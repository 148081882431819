import Image from 'next/image';
import { Fragment } from 'react';

import {
  TextKeyValuePair,
  TextWithIcon,
  TooltipWithRef
} from '@/components/atomic/nuclei';
import { ALLOCATION_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { getSidebarBodyData } from '@/services/userEventCartList.service';

const PlannerAndAllocationStatusInfo = ({ allocationStatus, opsUser }) => (
  <div className='flex gap-4 py-1'>
    <Image
      alt='event planner brand icon'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/planner-brand-icon-v1.svg`}
      style={{ height: 48, width: 48 }}
      width={0}
    />
    <TextKeyValuePair
      {...{
        className: 'flex-col !gap-0',
        label: opsUser?.name || 'NA',
        labelClass: 'text-lg font-semibold text-dim-gray',
        value: ALLOCATION_STATUS[allocationStatus]?.label || 'NA',
        valueClassName: `text-sm font-medium px-2 rounded py-[2px] w-fit ${ALLOCATION_STATUS[allocationStatus]?.className}`,
        valueId: 'event-allocation-status'
      }}
    />
  </div>
);

const EventCartListSideBarBody = ({ router, userEvent }) => {
  const {
    user: { id: userId },
    userEventDetails,
    zendeskTicketId
  } = userEvent;
  const [
    {
      allocationStatus,
      eventAddress,
      eventContactName,
      eventDate,
      eventStartTime,
      eventVerticals,
      expectedGuestCount,
      opsUser
    }
  ] = userEventDetails;

  const sidebarBodyData = getSidebarBodyData({
    eventAddress,
    eventContactName,
    eventDate,
    eventStartTime,
    eventVerticals,
    expectedGuestCount,
    router,
    userId,
    zendeskTicketId
  });

  return (
    <Fragment>
      <PlannerAndAllocationStatusInfo {...{ allocationStatus, opsUser }} />
      {sidebarBodyData.map((props, index) => (
        <TooltipWithRef
          key={`${props.id}-${index}`}
          text={props.tooltipText}
        >
          <TextWithIcon {...props} />
        </TooltipWithRef>
      ))}
    </Fragment>
  );
};

export default EventCartListSideBarBody;
