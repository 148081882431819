import { useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';

import { TOOLTIP_DELAY_TIME } from '@/config/common';

const TooltipWithRef = ({ children, text }) => {
  const targetRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen((prev) => !prev);
  };

  return (
    <>
      <span ref={targetRef}>{children}</span>
      <Tooltip
        delay={{ hide: 0, show: TOOLTIP_DELAY_TIME }}
        innerClassName='bg-black text-white text-xs p-1.5 px-2 rounded z-50'
        isOpen={tooltipOpen}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-120, 0]
            }
          }
        ]}
        placement='bottom'
        target={targetRef}
        toggle={toggleTooltip}
      >
        {text}
      </Tooltip>
    </>
  );
};

export default TooltipWithRef;
