import _ from 'lodash';
import Image from 'next/image';
import Link from 'next/link';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL, USER_EVENT_STAGE } from '@/config/common';

const sortFields = (fields) =>
  fields
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((a) => _.omit(a, 'sortOrder'));

const AddNewCartFeasibilityInfo = ({ cartInfoFields }) => (
  <div className='add-new-cart-info border border-neutral rounded-lg p-8'>
    <TextKeyValuePair
      {...{
        className: 'flex flex-col gap-1 items-center',
        label: 'Looking to add new cart?',
        labelClass: 'text-brand text-2xl font-semibold',
        value: `Here's what you need to do`,
        valueClassName: 'text-brand text-sm font-medium'
      }}
    />
    <div className='flex items-center justify-center gap-14'>
      <Image
        alt='add new cart'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/add-new-cart-feasibility-info-icon.svg`}
        style={{ height: 320, width: 331 }}
        width={0}
      />
      <div className='mt-10'>
        <Text
          {...{
            className: 'text-sm font-medium',
            content: '1. Make sure the event stage is set to'
          }}
        />
        <div className='flex items-center gap-3 mt-4'>
          <TextWithIcon
            {...{
              className: '',
              iconSuffix: {
                alt: 'right arrow',
                icon: 'arrow-right.svg',
                iconHeight: 20,
                iconWidth: 20
              },
              label: USER_EVENT_STAGE.EVENT_CREATED.label,
              labelStyle:
                'bg-white py-1 text-sm font-medium text-brand px-2 rounded italic'
            }}
          />
          <TextWithIcon
            {...{
              className: 'relative',
              iconSuffix: {
                alt: 'completed green icon',
                icon: 'check-completed-green-icon.svg',
                iconHeight: 12,
                iconStyle: 'absolute -right-1 -top-1',
                iconWidth: 12
              },
              label: USER_EVENT_STAGE.EPIC_GENERATED.label,
              labelStyle:
                'bg-white py-1 text-sm font-medium text-brand px-2 rounded italic'
            }}
          />
        </div>
        <Text
          {...{
            className: 'mt-4 text-sm font-medium',
            content: '2. Verify that below fields are filled in'
          }}
        />
        <div className='grid grid-cols-2 gap-2 mt-3'>
          {sortFields(cartInfoFields).map(({ icon, iconAlt, label }) => (
            <TextWithIcon
              key={`${label} - ${icon}`}
              {...{
                alt: iconAlt,
                className: '',
                icon,
                iconHeight: 12,
                iconWidth: 12,
                label,
                labelStyle: 'text-xs italic'
              }}
            />
          ))}
        </div>
        <div className='flex gap-2 mt-4 text-sm font-medium text-brand'>
          <Text {...{ content: '🪄 To check out the video guide' }} />
          <Link
            className='cursor-pointer'
            href='https://www.loom.com/share/af808c71b4874905ba730e55058812ab?sid=f07602b5-6644-4c54-9520-a0184663144f'
            target='_blank'
          >
            <Text {...{ className: 'text-blue', content: 'Click here' }} />
          </Link>
        </div>
      </div>
    </div>
    <Text
      {...{
        className: 'text-sm font-medium italic text-dim-gray text-center mt-6',
        content: 'Have fun planning the celebration! 🎉'
      }}
    />
  </div>
);

export default AddNewCartFeasibilityInfo;
