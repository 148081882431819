import _ from 'lodash';
import { memo, useMemo } from 'react';
import {
  useCurrentRefinements,
  useSearchBox,
  useStats
} from 'react-instantsearch';

import {
  CustomInfiniteHits,
  ListCard,
  NoResultsBoundary,
  PageNotFound
} from '@/components/atomic/atoms';
import { CurrentRefinements, Text } from '@/components/atomic/nuclei';
import transformCurrentRefinementItemsLabel from '@/helpers/searchkit';
import { transformSearchResultList } from '@/services/navigation.service';

const handleTransformItems = (hits) => {
  const transformedHits = transformSearchResultList({ searchResults: hits });
  return transformedHits;
};

const OrderListSearchResultsPanel = memo(
  ({ removeCurrentRefinement, showFilters }) => {
    const memoizedHandleTransformItems = useMemo(
      () => handleTransformItems,
      []
    );
    const { items: currentRefinements } = useCurrentRefinements();
    const { nbHits: resultsCount } = useStats();
    const { query } = useSearchBox();
    const isResultUndefined = _.isUndefined(resultsCount);

    return (
      <div
        className={`flex flex-col px-2 w-full h-screen relative ${
          showFilters ? 'ml-80' : 'ml-0'
        }`}
      >
        <div
          className={`${currentRefinements.length > 0 ? 'mb-4 mt-4' : 'my-0'}`}
        >
          {!isResultUndefined && (
            <Text
              {...{
                className: 'text-xl font-medium text-brand mb-4',
                content: `${resultsCount} Orders`
              }}
            />
          )}
          <CurrentRefinements
            {...{
              excludedAttributes: ['query'],
              removeCurrentRefinement,
              transformItems: transformCurrentRefinementItemsLabel
            }}
          />
        </div>
        <NoResultsBoundary
          fallback={
            <PageNotFound
              {...{
                className: 'py-20',
                description: `Sorry, we couldn't find any matches for "${query}"`,
                iconHeight: 314,
                iconWidth: 805,
                notFoundImage: 'search-result-not-found.svg',
                title: 'No search result'
              }}
            />
          }
        >
          <CustomInfiniteHits
            {...{
              infiniteHitsProps: {
                classNames: {
                  item: 'p-0 border-none shadow-none',
                  list: 'flex flex-col gap-4',
                  loadMore: 'border-none hover:bg-none text-brand font-medium',
                  root: 'flex flex-col flex-1'
                },
                hitComponent: ({ hit: listCard }) => (
                  <ListCard
                    {...{ additionalLoaderClass: 'ml-[50rem]', listCard }}
                  />
                ),
                showPrevious: false,
                transformItems: memoizedHandleTransformItems,
                translations: { showMoreButtonText: 'Show more' }
              }
            }}
          />
        </NoResultsBoundary>
      </div>
    );
  }
);

OrderListSearchResultsPanel.displayName = 'OrderListSearchResultsPanel';

export default OrderListSearchResultsPanel;
