import Image from 'next/image';

import { EPICExplanation } from '@/components/atomic/atoms';
import {
  Button,
  Text,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import {
  mediaStoreBaseURL,
  staticMediaStoreBaseURL,
  USER_EVENT_ACTION
} from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';
import { getFormattedDate } from '@/lib/time';
import { isEmptyOrNull, parseNumberedLabel } from '@/lib/utils';
import { isPlannerManager } from '@/services/actionCenter.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import {
  getLabelsBasedOnAllocationStatusAndEPICProgress,
  shouldShowAcceptReassignCTA
} from '@/services/userEvent.service';

const ActionPanel = ({
  isPreviewCardOpen,
  labels,
  onClickCard,
  showAcceptReassignCTA
}) => (
  <div className='flex gap-4'>
    {showAcceptReassignCTA ? (
      <>
        <Button
          className='border border-brand px-2 py-2 md:py-1 rounded text-brand font-medium text-xs'
          disabled={isPreviewCardOpen}
          label={USER_EVENT_ACTION.REASSIGN.label}
          onClick={(e) => {
            e.stopPropagation();
            onClickCard({ action: USER_EVENT_ACTION.REASSIGN.value });
          }}
          width='w-1/2 md:w-auto'
        />
        <Button
          className='px-2 py-2 md:py-1 rounded text-white bg-brand-gradient font-medium text-xs'
          disabled={isPreviewCardOpen}
          label={USER_EVENT_ACTION.ACCEPT.label}
          onClick={(e) => {
            e.stopPropagation();
            onClickCard({ action: USER_EVENT_ACTION.ACCEPT.value });
          }}
          width='w-1/2 md:w-auto'
        />
      </>
    ) : (
      labels.map((labelProps, index) => (
        <Text
          key={`${labelProps.content}_${index}`}
          {...labelProps}
        />
      ))
    )}
  </div>
);

const NewAssignedUserEventCard = ({
  containerClassName = 'flex gap-2 md:gap-4 bg-event-gradient rounded-t-[7px] w-min min-w-fit md:w-[420px] p-3 relative',
  isPreviewCardOpen,
  onClickCard = () => {},
  showOverlay,
  source,
  upcomingUserEventDetail: {
    allocationStatus,
    checkoutEventIconUrl,
    epicGenerationProgressPercentage,
    eventCityOrAddress,
    eventDate,
    eventStageUpdatedAt,
    eventType,
    eventVerticalNames,
    expectedGuestCount,
    hostCreditEntityName,
    hostName,
    hostSegmentName,
    opsUser,
    stage,
    userEventId,
    userEventNumber,
    zendeskTicketId
  }
}) => {
  const tooltipConfigList = [
    { target: `zendesk-ticket-id-${userEventId}`, text: 'Zendesk Ticket Id' },
    { target: `user-event-number-${userEventId}`, text: 'User Event Number' }
  ];

  const { name: plannerName } = opsUser || {};

  const showAcceptReassignCTA = shouldShowAcceptReassignCTA({
    allocationStatus,
    stage
  });

  const labels = getLabelsBasedOnAllocationStatusAndEPICProgress({
    allocationStatus,
    epicGenerationProgressPercentage,
    stage
  });

  const onClickNewAssignedUserEventCard = () => {
    if (source === PAGE_NAME.ACTION_CENTER.label && !isEmptyOrNull(labels)) {
      window.open(
        getPageURL({
          pageName: PAGE_NAME.EVENT_CART_LIST.label,
          pathParams: { userEventId }
        }),
        '_blank'
      );
    } else {
      onClickCard({ action: null });
    }
  };

  const [isMobile] = useIsMobile();
  return (
    <div
      className={`flex flex-col border border-pink rounded-lg shadow-navigate-cart hover:border-brand relative ${
        isPreviewCardOpen ? '!border-brand' : ''
      }`}
      onClick={onClickNewAssignedUserEventCard}
    >
      <Image
        alt='triangle icon'
        className={`absolute -right-4 top-14 ${
          isPreviewCardOpen ? '' : 'hidden'
        }`}
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/triangle-icon.svg`}
        style={{ height: 24, width: 27 }}
        unoptimized={true}
        width={0}
      />
      <TextWithIcon
        {...{
          alt: 'planner icon orange',
          className: `hidden md:block shadow-none py-[1px] absolute top-[1px] left-[1px] bg-white rounded-tl-[7px] rounded-br-[7px] px-1 z-[8] ${
            showOverlay ? 'bg-opacity-50' : ''
          }`,
          gap: 'gap-[2px] md:gap-1 px-1',
          icon: 'planner-brand-icon-v1.svg',
          iconHeight: isMobile ? 8 : 12,
          iconWidth: isMobile ? 8 : 12,
          label: plannerName,
          labelStyle:
            'text-[8px] md:text-[10px] font-light md:font-medium text-dim-gray',
          show: isPlannerManager && !isEmptyOrNull(plannerName)
        }}
      />
      <TextWithIcon
        {...{
          alt: 'time red icon',
          className: `shadow-none py-[1px] absolute top-[1px] right-[1px] bg-white rounded-bl-[7px] rounded-tr-[7px] px-1 z-[8] ${
            showOverlay ? 'bg-opacity-50' : ''
          }`,
          gap: 'gap-[2px] md:gap-1 px-1',
          icon: 'time-icon-red.svg',
          iconHeight: isMobile ? 8 : 12,
          iconWidth: isMobile ? 8 : 12,
          label: getFormattedDate({
            date: eventStageUpdatedAt,
            format: 'Do MMM YYYY, hh.mm A'
          }),
          labelStyle: 'text-[8px] md:text-[10px] font-medium text-dim-gray'
        }}
      />
      <div className={`${containerClassName} pt-4 md:pt-6`}>
        <Image
          alt='event image'
          className='rounded-lg'
          height={0}
          src={`${mediaStoreBaseURL}/${checkoutEventIconUrl}`}
          style={{ height: isMobile ? 48 : 72, width: isMobile ? 48 : 72 }}
          unoptimized={true}
          width={0}
        />
        <Text
          {...{
            className:
              'text-xs md:text-sm text-white truncate-text-three-line',
            content: (
              <EPICExplanation
                {...{
                  epicParameters: {
                    city: eventCityOrAddress,
                    eventDate,
                    eventType,
                    guests: expectedGuestCount,
                    hostCreditEntityName,
                    hostName,
                    hostType: hostSegmentName,
                    userEventId,
                    verticals: eventVerticalNames
                  }
                }}
              />
            )
          }}
        />
      </div>

      <div className='flex flex-col md:flex-row gap-3 md:gap-0 md:items-center p-3 justify-between'>
        <div className='flex justify-between'>
          <div className='flex gap-4'>
            <TextWithIcon
              {...{
                alt: 'zendesk icon',
                className: 'shadow-none',
                gap: 'gap-[5px]',
                icon: 'zendesk-icon.svg',
                iconHeight: isMobile ? 12 : 20,
                iconWidth: isMobile ? 12 : 20,
                id: `zendesk-ticket-id-${userEventId}`,
                label: parseNumberedLabel(zendeskTicketId),
                labelStyle: 'text-[10px] md:text-sm font-medium text-dim-gray'
              }}
            />
            <TextWithIcon
              {...{
                alt: 'user event brand icon',
                className: 'shadow-none',
                gap: 'gap-[5px]',
                icon: 'user-event-brand-icon.svg',
                iconHeight: isMobile ? 12 : 20,
                iconWidth: isMobile ? 12 : 20,
                id: `user-event-number-${userEventId}`,
                label: parseNumberedLabel(userEventNumber),
                labelStyle: 'text-[10px] md:text-sm font-medium text-dim-gray'
              }}
            />
          </div>
          <TextWithIcon
            {...{
              alt: 'event planner brand icon',
              className: `md:hidden shadow-none`,
              gap: 'gap-[2px] md:gap-1 px-1',
              icon: 'planner-brand-icon-v1.svg',
              iconHeight: isMobile ? 12 : 20,
              iconWidth: isMobile ? 12 : 20,
              label: plannerName,
              labelStyle:
                'text-[10px] md:text-sm font-medium text-dim-gray max-w-[7rem] truncate',
              show: isPlannerManager && !isEmptyOrNull(plannerName)
            }}
          />
        </div>
        <ActionPanel
          {...{
            isPreviewCardOpen,
            labels,
            onClickCard,
            showAcceptReassignCTA
          }}
        />
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

export default NewAssignedUserEventCard;
