import Link from 'next/link';

import {
  Avatar,
  Text,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { HOST_INTERACTION_STATUS } from '@/config/common';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import { convertToShortMonthDateFormat } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const UserEventInfoColumnOne = ({
  hostSegment,
  email,
  hostCreditEntityName,
  mobile,
  name
}) => {
  const userEventInfoColumnOne = [
    {
      alt: 'phone number icon',
      icon: 'phone-brand-icon.svg',
      id: 'host-phone-number',
      label: convertToStandardMobileNumberFormat(mobile) || 'NA',
      show: true
    },
    {
      alt: 'email icon',
      icon: 'email-brand-icon.svg',
      id: 'host-email-id',
      label: email || 'NA',
      show: true
    },
    {
      alt: 'host type icon',
      icon: 'host-type-brand-v1-icon.svg',
      id: 'host-type',
      label: hostSegment?.name || 'NA',
      show: true
    },
    {
      alt: 'host credit entity company',
      icon: 'host-credit-entity-brand-icon.svg',
      label: hostCreditEntityName || 'NA',
      show: true
    }
  ];

  const tooltipConfigList = [
    {
      target: 'host-phone-number',
      text: 'Host Phone Number'
    },
    {
      target: 'host-email-id',
      text: 'Host Email Id'
    },
    {
      target: 'host-type',
      text: 'Host Type'
    }
  ];

  return (
    <div className='flex flex-col mt-2'>
      <Text
        {...{
          className: 'text-xl font-medium text-nero truncate max-w-[35rem]',
          content: name || '-'
        }}
      />

      <div className='flex gap-3 mt-3'>
        {userEventInfoColumnOne.map(({ alt, icon, id = '', label, show }) => (
          <TextWithIcon
            key={`${label} - ${icon}`}
            {...{
              alt,
              className: 'py-1 rounded',
              icon,
              id,
              iconHeight: 20,
              iconWidth: 20,
              label,
              labelStyle: 'text-sm text-nero max-w-lg-20 truncate',
              show
            }}
          />
        ))}
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};
const UserEventInfoColumnTwo = ({
  hostNumber,
  interactionStatus,
  createdAt
}) => {
  const tooltipConfigList = [
    {
      target: 'host-number',
      text: 'Host Number'
    },
    {
      target: 'host-status',
      text: 'Host Status'
    },
    {
      target: 'created-at-date',
      text: 'Created At Date'
    }
  ];
  return (
    <div className='flex flex-col ml-auto'>
      <div className='flex justify-content-end ml-auto gap-2 relative -top-[12px] -right-[12px]'>
        <Text
          {...{
            className: `text-sm self-center font-medium px-2 py-1 rounded-lg ${HOST_INTERACTION_STATUS[interactionStatus]?.className}`,
            content: HOST_INTERACTION_STATUS[interactionStatus]?.label,
            id: 'host-status'
          }}
        />
        <TextWithIcon
          {...{
            alt: 'host number icon',
            className:
              'curve-shadow rounded-tr rounded-bl relative z-20 px-2 py-2',
            icon: 'host-number-brand-v1-icon.svg',
            id: 'host-number',
            label: parseNumberedLabel(hostNumber),
            labelStyle: 'text-dim-gray text-sm font-medium'
          }}
        />
      </div>
      <div className='flex gap-4 justify-end mt-3'>
        <TextWithIcon
          {...{
            alt: 'date',
            className: 'py-1 px-2 rounded',
            icon: 'calender-grey.svg',
            id: 'created-at-date',
            label: convertToShortMonthDateFormat(createdAt),
            labelStyle: `text-dim-gray text-sm font-light`
          }}
        />
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

const HostProfileCard = ({ host }) => {
  const {
    createdAt,
    hostSegment,
    email,
    hostCreditEntity,
    hostNumber,
    id: hostId,
    interactionStatus,
    mobile,
    name
  } = host;
  const { name: hostCreditEntityName = null } = hostCreditEntity || {};
  return (
    <Link
      href={getPageURL({
        pageName: PAGE_NAME.HOST_EVENT_LIST.label,
        pathParams: {
          userId: hostId
        }
      })}
    >
      <div className='flex gap-3 bg-white border border-neutral hover:border-brand p-3 shadow-card rounded-lg'>
        <Avatar
          {...{
            badgeStyle: 'w-[74px] h-[74px] bg-brand-gradient',
            name,
            textStyle: 'text-white text-[40px]'
          }}
        />
        <UserEventInfoColumnOne
          {...{
            hostSegment,
            email,
            hostCreditEntityName,
            mobile,
            name
          }}
        />
        <UserEventInfoColumnTwo
          {...{
            createdAt,
            hostNumber,
            interactionStatus
          }}
        />
      </div>
    </Link>
  );
};

export default HostProfileCard;
