import _ from 'lodash';
import Image from 'next/image';
import { memo, useState } from 'react';

import { staticMediaStoreBaseURL } from '@/config/common';
import copyToClipBoard from '@/lib/copyToClipBoard';

const DefaultTextWithIcon = ({
  alt,
  className,
  gap = 'gap-2',
  icon,
  iconHeight,
  iconId,
  iconStyle,
  iconWidth,
  id,
  label,
  labelStyle,
  onClick,
  suffixIcon,
  suffixIconAlt,
  suffixIconHeight,
  suffixIconStyle,
  suffixIconWidth
}) => (
  <div className={`flex items-center gap-2 ${className}`}>
    <div
      className={`flex items-center ${gap}`}
      id={iconId}
      onClick={onClick}
    >
      {icon && (
        <Image
          alt={alt}
          className={iconStyle}
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/${icon}`}
          style={{
            height: iconHeight,
            width: iconWidth
          }}
          width={0}
        />
      )}
      <div className={labelStyle}>{label}</div>
    </div>
    {suffixIcon && (
      <Image
        alt={suffixIconAlt}
        className={suffixIconStyle}
        height={0}
        id={id}
        src={`${staticMediaStoreBaseURL}/icons/${suffixIcon}`}
        style={{
          height: suffixIconHeight,
          width: suffixIconWidth
        }}
        width={0}
      />
    )}
  </div>
);

const HoveredTextWithIcon = ({
  className,
  gap = 'gap-2',
  hoverPrefixIcon,
  hoverPrefixIconAlt,
  hoverPrefixIconHeight,
  hoverPrefixIconOnClick = () => {},
  hoverPrefixIconStyle,
  hoverPrefixIconWidth,
  hoverSuffixIcon,
  hoverSuffixIconAlt,
  hoverSuffixIconHeight,
  hoverSuffixIconStyle,
  hoverSuffixIconWidth,
  id,
  onClick,
  style,
  text
}) => (
  <div className={`flex gap-2 items-center ${className}`}>
    <div
      className={`flex items-center ${gap}`}
      onClick={hoverPrefixIconOnClick}
    >
      <Image
        alt={hoverPrefixIconAlt}
        className={hoverPrefixIconStyle}
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/${hoverPrefixIcon}`}
        style={{
          height: hoverPrefixIconHeight,
          width: hoverPrefixIconWidth
        }}
        width={0}
      />
      <div
        className={style}
        onClick={onClick}
      >
        {text}
      </div>
    </div>
    {hoverSuffixIcon && (
      <Image
        alt={hoverSuffixIconAlt}
        className={hoverSuffixIconStyle}
        height={0}
        id={id}
        src={`${staticMediaStoreBaseURL}/icons/${hoverSuffixIcon}`}
        style={{
          height: hoverSuffixIconHeight,
          width: hoverSuffixIconWidth
        }}
        width={0}
      />
    )}
  </div>
);

const copyTextToClipboard = async ({ clipboardText, event, setCopyIcon }) => {
  event.preventDefault();
  event.stopPropagation();
  const isClipboardAvailable = Boolean(navigator.clipboard);
  if (isClipboardAvailable) {
    await copyToClipBoard(clipboardText);
    setCopyIcon('check-completed-black-icon');
    setTimeout(() => {
      setCopyIcon('copy-brand-icon-v1');
    }, 2000);
  }
};
const TextWithIcon = memo(
  ({
    alt = 'icon',
    className = 'shadow-card py-1 px-2 rounded',
    clipboardText,
    gap,
    icon,
    iconHeight = 16,
    iconStyle,
    iconSuffix = {},
    iconWidth = 16,
    id: iconId,
    label,
    labelStyle = 'text-sm text-nero',
    onClick = () => {},
    onHover,
    onHoverCopy = false,
    show = true
  }) => {
    const [copyIcon, setCopyIcon] = useState('copy-brand-icon-v1');
    const {
      id,
      alt: suffixIconAlt,
      icon: suffixIcon,
      iconHeight: suffixIconHeight,
      iconStyle: suffixIconStyle,
      iconWidth: suffixIconWidth
    } = iconSuffix;

    let additionalHoverProps = {
      ...onHover
    };

    if (onHoverCopy) {
      additionalHoverProps = {
        iconPrefix: {
          alt: 'copy icon',
          icon: `${copyIcon}.svg`,
          iconHeight,
          iconStyle: `${iconStyle} cursor-pointer`,
          iconWidth,
          onClick: (event) => {
            const content = clipboardText ?? label ?? '';
            copyTextToClipboard({
              clipboardText: content,
              event,
              setCopyIcon
            });
          }
        },
        iconSuffix,
        label: {
          style: labelStyle,
          text: label
        }
      };
    }
    const {
      label: { text, style } = {},
      iconPrefix: {
        alt: hoverPrefixIconAlt,
        icon: hoverPrefixIcon,
        iconHeight: hoverPrefixIconHeight,
        iconStyle: hoverPrefixIconStyle,
        iconWidth: hoverPrefixIconWidth,
        onClick: hoverPrefixIconOnClick
      } = {},
      iconSuffix: {
        alt: hoverSuffixIconAlt,
        icon: hoverSuffixIcon,
        iconHeight: hoverSuffixIconHeight,
        iconStyle: hoverSuffixIconStyle,
        iconWidth: hoverSuffixIconWidth
      } = {}
    } = additionalHoverProps || {};

    const [isHovered, setIsHovered] = useState(false);

    return (
      Boolean(show) && (
        <div
          className='group'
          onMouseEnter={() =>
            !_.isEmpty(additionalHoverProps) && setIsHovered(true)
          }
          onMouseLeave={() => isHovered && setIsHovered(false)}
        >
          {isHovered ? (
            <HoveredTextWithIcon
              {...{
                className,
                hoverPrefixIcon,
                hoverPrefixIconAlt,
                hoverPrefixIconHeight,
                hoverPrefixIconOnClick,
                hoverPrefixIconStyle,
                hoverPrefixIconWidth,
                hoverSuffixIcon,
                hoverSuffixIconAlt,
                hoverSuffixIconHeight,
                hoverSuffixIconStyle,
                hoverSuffixIconWidth,
                id,
                onClick,
                style,
                text
              }}
            />
          ) : (
            <DefaultTextWithIcon
              {...{
                alt,
                className,
                gap,
                icon,
                iconHeight,
                iconId,
                iconStyle,
                iconWidth,
                id,
                label,
                labelStyle,
                onClick,
                suffixIcon,
                suffixIconAlt,
                suffixIconHeight,
                suffixIconStyle,
                suffixIconWidth
              }}
            />
          )}
        </div>
      )
    );
  }
);

TextWithIcon.displayName = 'TextWithIcon';

export default TextWithIcon;
