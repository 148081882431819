import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';
import { useSearchBox } from 'react-instantsearch';

import {
  CustomInfiniteHits,
  ListCard,
  NoResultsBoundary,
  PageNotFound,
  TabMenu
} from '@/components/atomic/atoms';
import { NavigationSearchBar } from '@/components/atomic/molecules';
import { CloseIcon } from '@/components/atomic/nuclei';
import { isProduction } from '@/config/common';
import {
  ALL_TAB_CATEGORY_CONFIG,
  DEFAULT_TAB_CATEGORY,
  TAB_CATEGORY_ATTRIBUTE,
  TAB_CATEGORY_CONFIG_LIST
} from '@/config/searchkit/navigation';
import { transformSearchResultList } from '@/services/navigation.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const SearchResultsPanel = ({ memoizedHandleTransformItems }) => {
  const { query } = useSearchBox();

  return (
    <div className='flex justify-center gap-6 px-6'>
      <NoResultsBoundary
        fallback={
          <PageNotFound
            {...{
              className: 'py-20',
              description: `Sorry, we couldn't find any matches for "${query}"`,
              iconHeight: 314,
              iconWidth: 805,
              notFoundImage: 'search-result-not-found.svg',
              title: 'No search result'
            }}
          />
        }
      >
        <CustomInfiniteHits
          {...{
            infiniteHitsProps: {
              classNames: {
                item: 'p-0 border-none shadow-none',
                list: 'flex flex-col gap-4',
                loadMore: 'border-none hover:bg-none text-brand font-medium',
                root: 'flex flex-col flex-1'
              },
              hitComponent: ({ hit: listCard }) => (
                <ListCard {...{ listCard }} />
              ),
              showPrevious: false,
              transformItems: memoizedHandleTransformItems,
              translations: {
                showMoreButtonText: 'Show more'
              }
            }
          }}
        />
      </NoResultsBoundary>
    </div>
  );
};

const NavigationBodyOrganism = memo(
  ({
    query,
    register,
    searchableAttribute,
    selectedTabMenu,
    setQuery,
    setSearchableAttribute,
    setSelectedTabMenu,
    setShowResults,
    showResults
  }) => {
    const { refine: searchRefine } = useSearchBox({ attribute: 'query' });

    const router = useRouter();

    const handleTransformItems = (newHits) => {
      const transformedHits = transformSearchResultList({
        searchResults: newHits
      });

      return transformedHits;
    };

    const memoizedHandleTransformItems = useMemo(
      () => handleTransformItems,
      []
    );

    return (
      <div className='relative'>
        <div className='sticky top-0 bg-white pt-4 flex flex-col justify-center items-center h-36 text-lg font-medium z-101'>
          <div className='absolute top-8 right-5 z-10'>
            <CloseIcon
              {...{
                height: 32,
                icon: 'close-brand-icon.svg',
                onClick: () => {
                  // TODO: Faizan/Hardik - use next/router. Do not use window.*
                  if (window.navigation.canGoBack) {
                    router.back();
                  } else {
                    router.replace(
                      getPageURL({ pageName: PAGE_NAME.WELCOME.label })
                    );
                  }
                },
                width: 32
              }}
            />
          </div>
          <TabMenu
            {...{
              allTabMenuConfig: ALL_TAB_CATEGORY_CONFIG,
              attribute: TAB_CATEGORY_ATTRIBUTE,
              defaultTabCategory: DEFAULT_TAB_CATEGORY,
              onTabMenuSwitch: () => {
                searchRefine('');
              },
              selectedTabMenu,
              setQuery,
              setSelectedTabMenu,
              setShowResults,
              showTabMenuCount: !isProduction,
              tabCategoryConfigList: TAB_CATEGORY_CONFIG_LIST
            }}
          />
          <NavigationSearchBar
            {...{
              query,
              register,
              searchableAttribute,
              selectedTabMenu,
              setQuery,
              setSearchableAttribute,
              setShowResults
            }}
          />
        </div>
        {showResults ? (
          <SearchResultsPanel
            {...{
              memoizedHandleTransformItems
            }}
          />
        ) : (
          <PageNotFound
            {...{
              alt: 'entity not found',
              className: 'py-4',
              iconHeight: 513,
              iconWidth: 805,
              notFoundImage: 'entity-not-found-icon.svg',
              title: ''
            }}
          />
        )}
      </div>
    );
  }
);

NavigationBodyOrganism.displayName = 'NavigationBodyOrganism';

export default NavigationBodyOrganism;
