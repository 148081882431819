import { useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';

import { OrderListFilterHeader } from '@/components/atomic/atoms';
import {
  OrderListFilters,
  OrderListSearchBar,
  OrderListSearchResultsPanel
} from '@/components/atomic/molecules';
import { NAVIGATION_INDEX } from '@/config/searchkit/navigation';
import {
  DATE_FILTER_ATTRIBUTE,
  DEFAULT_HITS_PER_PAGE,
  SEARCHABLE_ATTRIBUTES
} from '@/config/searchkit/orders';
import createClient from '@/lib/haflaSearchkitClient';
import { PLANNER_PORTAL_FQDN } from '@/services/connections.service';
import { getAuthToken, getPlannerName } from '@/services/identity.service';
import { SEARCH_KIT_ORDERS_SEARCH_API_URL } from '@/services/url.service';

const searchClient = createClient({
  headers: {
    'x-access-token': getAuthToken(),
    'x-origin-domain': PLANNER_PORTAL_FQDN
  },
  url: SEARCH_KIT_ORDERS_SEARCH_API_URL
});

const OrderListOrganism = ({ showMyUpcomingOrders }) => {
  const [searchableAttribute, setSearchableAttribute] = useState(
    SEARCHABLE_ATTRIBUTES[0].esDocKey
  );
  const [showFilters, setShowFilters] = useState(true);
  const [clearBookingDateFilter, setClearBookingDateFilter] = useState(false);
  const [clearEventDateFilter, setClearEventDateFilter] = useState(false);

  const selectedAttributeLabel = Object.values(SEARCHABLE_ATTRIBUTES).find(
    ({ esDocKey }) => esDocKey === searchableAttribute
  )?.label;

  const restrictSearchableAttributes = searchableAttribute
    ? [searchableAttribute]
    : [];

  const initialUiStateProps = showMyUpcomingOrders
    ? { refinementList: { plannerName: [getPlannerName()] } }
    : {};

  const removeCurrentRefinement = (attribute) => {
    const toggleActions = {
      [DATE_FILTER_ATTRIBUTE.BOOKING_DATE]: () =>
        setClearBookingDateFilter(!clearBookingDateFilter),
      [DATE_FILTER_ATTRIBUTE.EVENT_DATE]: () =>
        setClearEventDateFilter(!clearEventDateFilter)
    };
    const executeToggle = toggleActions[attribute];
    if (executeToggle) {
      executeToggle();
    }
  };

  return (
    <InstantSearch
      {...{
        future: {
          preserveSharedStateOnUnmount: true
        },
        indexName: NAVIGATION_INDEX,
        initialUiState: {
          [NAVIGATION_INDEX]: initialUiStateProps
        },
        searchClient
      }}
    >
      <Configure
        {...{
          hitsPerPage: DEFAULT_HITS_PER_PAGE,
          restrictSearchableAttributes
        }}
      />
      <div className='!mt-4 container mx-auto'>
        <div className='flex gap-8 searchkit-filter-container sticky z-10 top-0 bg-white left-0 py-2'>
          <OrderListFilterHeader
            {...{
              onClickClearAll: () => {
                setClearBookingDateFilter(!clearBookingDateFilter);
                setClearEventDateFilter(!clearEventDateFilter);
              },
              setShowFilters,
              showFilters
            }}
          />
          <OrderListSearchBar
            {...{
              searchableAttribute,
              searchAttributeLabelMap: SEARCHABLE_ATTRIBUTES,
              selectedAttributeLabel,
              setSearchableAttribute
            }}
          />
        </div>
        <div className='flex gap-6'>
          <OrderListFilters
            {...{
              clearBookingDateFilter,
              clearEventDateFilter,
              setClearBookingDateFilter,
              setClearEventDateFilter,
              showFilters,
              showMyUpcomingOrders
            }}
          />
          <OrderListSearchResultsPanel
            {...{ removeCurrentRefinement, showFilters }}
          />
        </div>
      </div>
    </InstantSearch>
  );
};

export default OrderListOrganism;
