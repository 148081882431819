import { useState } from 'react';

import {
  InputField,
  SearchkitSelectFormInput,
  SelectTags
} from '@/components/atomic/nuclei';
import { TAG_OPTIONS_SELECTION_MODE } from '@/config/common';
import {
  HOST_CREDIT_ENTITY_DROPDOWN_HIT_LIMIT,
  NAVIGATION_INDEX,
  TAB_CATEGORY_ATTRIBUTE,
  TAB_CATEGORY_ENUM
} from '@/config/searchkit/navigation';
import { isEmptyOrNull } from '@/lib/utils';
import { hostProfileTabs } from '@/services/hostProfile.service';
import createClient from 'lib/haflaSearchkitClient';
import { PLANNER_PORTAL_FQDN } from 'services/connections.service';
import { getAuthToken } from 'services/identity.service';
import { SEARCH_KIT_NAVIGATION_SEARCH_API_URL } from 'services/url.service';

const searchClient = createClient({
  headers: {
    'x-access-token': getAuthToken(),
    'x-origin-domain': PLANNER_PORTAL_FQDN
  },
  url: SEARCH_KIT_NAVIGATION_SEARCH_API_URL
});

const HostProfileStepOne = ({
  clearErrors,
  errors,
  formActiveStepId,
  hostCreditEntityId,
  hostProfileMetadata,
  isEditable,
  onClickAddNewEntity,
  register,
  selectedHostCreditEntityName,
  setSelectedHostCreditEntityName,
  setValue,
  watch
}) => {
  const { hostTypeList } = hostProfileMetadata;
  const formStepVisibilityClass =
    formActiveStepId === hostProfileTabs.HOST_PROFILE.id ? 'block' : 'hidden';

  const [searchQuery, setSearchQuery] = useState();
  const disableSearchBox = !isEditable || !isEmptyOrNull(hostCreditEntityId);

  return (
    <div className={formStepVisibilityClass}>
      <div className='bg-white rounded-lg px-6 pb-4 flex flex-col gap-4 block'>
        <InputField
          {...{
            className: 'rounded-lg pl-10 !py-4',
            dbName: 'name',
            disabled: !isEditable,
            errors,
            iconURL: isEditable
              ? 'host-brand-v1-icon.svg'
              : 'host-black-v1-icon.svg',
            inputGroup: 'flex flex-col gap-2',
            label: 'Host Name *',
            labelClass: 'text-nero text-base font-medium',
            maxLength: 64,
            placeholder: 'Enter Contact Name',
            register: {
              ...register('name')
            }
          }}
        />
        <div className='flex gap-4'>
          <InputField
            {...{
              className: 'rounded-lg pl-10 !py-4',
              dbName: 'mobile',
              disabled: true,
              iconURL: isEditable
                ? 'phone-brand-icon.svg'
                : 'phone-black-icon.svg',
              inputGroup: 'flex flex-col gap-2 flex-1',
              label: 'Mobile Number',
              labelClass: 'text-nero text-base font-medium',
              maxLength: 15,
              register: { ...register('mobile') }
            }}
          />
          <InputField
            {...{
              className: 'rounded-lg pl-10 !py-4',
              dbName: 'email',
              disabled: !isEditable,
              iconURL: isEditable
                ? 'email-brand-icon.svg'
                : 'email-black-icon.svg',
              inputGroup: 'flex flex-col gap-2 flex-1',
              label: 'Contact Email',
              labelClass: 'text-nero text-base font-medium',
              maxLength: 50,
              onChange: (e) => setValue('email', e.target.value),
              placeholder: 'Enter Contact Email',
              register: { ...register('email') }
            }}
          />
        </div>
        <SelectTags
          {...{
            formActiveStepId,
            isEditable,
            selectedTagOptions: watch('hostSegmentId'),
            setSelectedTagOptions: (value) => setValue('hostSegmentId', value),
            tagOptions: hostTypeList,
            tagOptionsSelectionMode: TAG_OPTIONS_SELECTION_MODE.SINGLE,
            tagsFieldLabel: { label: 'Host Type' }
          }}
        />
        <SearchkitSelectFormInput
          {...{
            addNewLabel: '+ Add New Credit Entity',
            clearErrors,
            configureProps: {
              filters: `${TAB_CATEGORY_ATTRIBUTE}:${TAB_CATEGORY_ENUM.HOST_CREDIT_ENTITY}`,
              hitsPerPage: HOST_CREDIT_ENTITY_DROPDOWN_HIT_LIMIT,
              restrictSearchableAttributes: ['hostCreditEntityName']
            },
            dbName: 'hostCreditEntityId',
            disableSearchBox,
            errors,
            indexName: searchQuery
              ? NAVIGATION_INDEX
              : `${NAVIGATION_INDEX}_createdAt_desc`,
            label: {
              name: 'Credit Entity Name',
              className: 'text-nero text-base font-medium mb-2 block'
            },
            onClickAddNewEntity,
            placeholder: 'Select credit entity name',
            searchClient,
            selectedHostCreditEntityName,
            selectFormIcon: isEditable
              ? 'host-credit-entity-brand-icon.svg'
              : 'host-credit-entity-black-icon-v1.svg',
            setSearchQuery,
            setSelectedHostCreditEntityName,
            setValue
          }}
        />
      </div>
    </div>
  );
};

export default HostProfileStepOne;
