import Image from 'next/image';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { NavigationMenu } from '@/components/atomic/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const NavigationMenuContainer = ({
  isMenuOpen,
  menuClass,
  plannerName,
  setIsMenuOpen,
  setOpenAddHostModal
}) => {
  const router = useRouter();
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useOnClickOutside(menuRef, () => setIsMenuOpen(false));

  return (
    <div
      className='flex gap-6 cursor-pointer relative z-20'
      ref={menuRef}
    >
      <div className='w-10 h-10 ai-chat-icon'>
        <div className='inner bg-white w-9 h-9'>
          <Image
            alt='ai-chat-icon'
            className='rise-shake relative left-[5px] top-[6px]'
            height={0}
            onClick={() =>
              router.push(
                getPageURL({
                  pageName: PAGE_NAME.PLANNER_ASSISTANT.label
                })
              )
            }
            src={`${staticMediaStoreBaseURL}/icons/chat-with-ai.svg`}
            style={{ height: 26, width: 26 }}
            width={0}
          />
        </div>
      </div>

      <div
        className='relative welcome-menu hover:bg-brand-gradient rounded-lg w-10 h-10'
        onClick={toggleMenu}
      >
        <Image
          alt='menu brand icon'
          className='menu-white-icon relative left-[5px] top-[5px] cursor-pointer'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/menu-brand-icon.svg`}
          style={{ height: 30, width: 30 }}
          width={0}
        />
        <Image
          alt='menu white icon'
          className='hidden menu-red-icon relative left-[5px] top-[5px] cursor-pointer'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/menu-white-icon-v1.svg`}
          style={{ height: 30, width: 30 }}
          width={0}
        />
      </div>
      {isMenuOpen && (
        <NavigationMenu
          {...{
            menuClass,
            plannerName,
            router,
            setOpenAddHostModal,
            toggleMenu
          }}
        />
      )}
    </div>
  );
};

export default NavigationMenuContainer;
