import _ from 'lodash';

export const isBrowser = () => typeof window !== 'undefined';

export const rectifyAndReplaceEmptyStringWithNull = (inputObj) => {
  const obj = Array.isArray(inputObj) ? [...inputObj] : { ...inputObj };

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string' && obj[key].trim() === '') {
      obj[key] = null;
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      obj[key] = rectifyAndReplaceEmptyStringWithNull(obj[key]);
    }
  });

  return obj;
};

export const arrayOfObjectHasKey = ({ array, key }) =>
  array.every((obj) => key in obj);

export const isNilOrEmpty = (value) =>
  _.isNil(value) || value === '' || _.isEmpty(value);

export const getAvatarText = ({ name }) => {
  if (isNilOrEmpty(name)) {
    return '-';
  }
  return name
    ?.split(' ')
    .slice(0, 2)
    .map((partName) => partName.slice(0, 1))
    .join('')
    .toUpperCase();
};

export const excludeCharFromString = ({ string, char }) =>
  string
    .split('')
    .filter((c) => c !== char)
    .join('');

export const toProperCase = (sentence) => {
  if (!sentence || typeof sentence !== 'string') {
    return sentence;
  }

  return _.startCase(_.camelCase(sentence));
};

export const parseNumberedLabelForClipboard = (value) =>
  value && value !== 'NA' ? value : '';
export const parseNumberedLabel = (value) =>
  value && value !== 'NA' ? `# ${value}` : 'NA';

export const isEmptyOrNull = (value) => _.isNil(value) || _.isEmpty(value);

export const getHostDummyEmail = (mobile) =>
  `haflacustomer${mobile}@dummy.com`;
