import { USER_EVENT_SOURCE } from '@/config/common';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';
import { convertToMonthYearFormat } from '@/lib/time';
import * as HttpService from '@/services/http.service';
import { apiResponseParser } from '@/services/plannerPortal.service';
import {
  GET_CHECKOUT_EVENTS_API_URL,
  GET_CITY_DETAILS_API_URL,
  GET_CONTACT_METHODS_API_URL,
  GET_EVENT_SITE_TYPE_LIST_API_URL,
  GET_EVENT_VERTICALS_API_URL,
  GET_HOST_PROFILE_AND_USER_EVENTS_API_URL,
  GET_LEAD_EXIT_REASONS_API_URL,
  GET_USER_EVENT_CART_DETAILS_API_URL,
  UPDATE_USER_EVENT_API_URL
} from '@/services/url.service';

const getHostProfileAndUserEventsAPI = ({ userId }) =>
  HttpService.getWithAuth(
    GET_HOST_PROFILE_AND_USER_EVENTS_API_URL({ userId })
  );

const getUserEventCartDetailsAPI = ({ userEventId }) =>
  HttpService.getWithAuth(
    GET_USER_EVENT_CART_DETAILS_API_URL({ userEventId })
  );

const getEventSiteTypeListAPI = () =>
  HttpService.getWithAuth(GET_EVENT_SITE_TYPE_LIST_API_URL);

export const getCityDetailsAPI = () =>
  HttpService.getWithoutAuth(GET_CITY_DETAILS_API_URL);

const getLeadExitReasonsAPI = () =>
  HttpService.getWithAuth(GET_LEAD_EXIT_REASONS_API_URL);

const getContactMethodsAPI = () =>
  HttpService.getWithAuth(GET_CONTACT_METHODS_API_URL);

export const getEventVerticalsAPI = () =>
  HttpService.getWithAuth(GET_EVENT_VERTICALS_API_URL);

export const getCheckoutEventsAPI = () =>
  HttpService.getWithoutAuth(GET_CHECKOUT_EVENTS_API_URL);

const updateUserEventAPI = ({ userEventDetail, userEventId, userId }) =>
  HttpService.postWithAuth(
    UPDATE_USER_EVENT_API_URL(userId, userEventId),
    userEventDetail
  );

export const getHostEventStatisticsParamaters = ({
  createdAt,
  hostEventStatistics: {
    userEventCount,
    userEventsAEV,
    userEventsConverted,
    userEventsGEV
  }
} = {}) => ({
  createdAt: convertToMonthYearFormat({ dateTimeString: createdAt }),
  userEventCount: {
    label: 'Events Inquired',
    value: userEventCount
  },
  userEventsConverted: {
    alt: 'book-event-icon',
    icon: 'book-event-fill-icon.svg',
    label: 'Booked Events',
    value: userEventsConverted
  },
  userEventsGEV: {
    alt: 'event-gev-icon',
    icon: 'gev-fill-icon.svg',
    label: 'GEV',
    value: convertToCurrencyFormat({
      roundOff: true,
      value: parseFormatPriceNumberValueFromAPI(userEventsGEV),
      withCurrencyPrefix: false
    })
  },
  userEventsAEV: {
    alt: 'event-gev-icon',
    icon: 'gev-fill-icon.svg',
    label: 'AEV',
    value: convertToCurrencyFormat({
      roundOff: true,
      value: parseFormatPriceNumberValueFromAPI(userEventsAEV),
      withCurrencyPrefix: false
    })
  }
});

export const getHostTouchPoints = ({ email, mobile, requesterType }) => [
  {
    alt: 'phone number icon',
    className: 'gap-2 mt-6',
    icon: 'phone-brand-icon.svg',
    iconHeight: 24,
    iconWidth: 24,
    id: 'host-phone-number',
    label: convertToStandardMobileNumberFormat(mobile) || 'NA',
    labelStyle: 'text-dim-gray font-medium',
    show: true
  },
  {
    alt: 'host type icon',
    className: 'gap-2 mt-3',
    icon: 'host-type-brand-v1-icon.svg',
    iconHeight: 24,
    iconWidth: 24,
    id: 'host-type',
    label: requesterType || 'NA',
    labelStyle: 'text-dim-gray font-medium',
    show: true
  },
  {
    alt: 'email icon',
    className: 'gap-2 mt-3',
    icon: 'email-brand-icon.svg',
    iconHeight: 24,
    iconWidth: 24,
    id: 'host-email-id',
    label: email || 'NA',
    labelStyle: 'max-w-[23rem] truncate font-medium text-dim-gray',
    show: true
  }
];

export const getStatusBasedOptionsForUserEventCard = ({ isPastEvent }) => {
  let dictionary;
  switch (isPastEvent) {
    case true:
      dictionary = {
        dateIcon: 'calendar-gray-with-frame.svg',
        eventIcon: 'user-event-black-icon.svg',
        eventStatusSpecificClassName: 'expired-event',
        imgStyle: 'opacity-50',
        locationIcon: 'location-black-icon.svg',
        userIcon: 'planner-black-icon-v1.svg',
        zendeskIcon: 'zendesk-gray-icon.svg'
      };
      break;
    default:
      dictionary = {
        dateIcon: 'date-brand-icon.svg',
        eventIcon: 'user-event-brand-icon.svg',
        locationIcon: 'location-brand-icon.svg',
        userIcon: 'planner-brand-icon-v1.svg',
        zendeskIcon: 'zendesk-icon.svg'
      };
      break;
  }
  return dictionary;
};

export const fetchHostProfileAndUserEvents = async ({
  hostId,
  setHostProfileAndUserEvents,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { status, entity, message } = await getHostProfileAndUserEventsAPI({
    userId: hostId
  });
  const isUserPresent = status && entity.user;
  if (isUserPresent) {
    setHostProfileAndUserEvents(entity);
  } else {
    setShowToast({ message, show: true, successToast: status });
  }
  setLoading(false);
};

export const EVENT_PROFILE_FORM_STEPS = {
  BASIC_EVENT_PROFILE: {
    id: '#basic-user-event-profile',
    label: 'Event Profile'
  },
  HOST_PROFILE: {
    id: '#host-profile',
    label: 'Host Profile'
  },
  EVENT_DEEP_DIVE: {
    id: '#event-deep-dive',
    label: 'Event Deep Dive'
  },
  TERMINATE: {
    id: '#terminate-user-event',
    label: 'Terminate Event'
  }
};

export const eventProfileStepFieldErrorConfig = {
  [EVENT_PROFILE_FORM_STEPS.BASIC_EVENT_PROFILE.id]: [
    'checkoutEventId',
    'eventDate'
  ],
  [EVENT_PROFILE_FORM_STEPS.EVENT_DEEP_DIVE.id]: [
    'budget',
    'eventDurationInDays',
    'eventTitle',
    'expectedGuestCount',
    'opportunityValue'
  ],
  [EVENT_PROFILE_FORM_STEPS.HOST_PROFILE.id]: [
    'eventContactEmail',
    'eventContactName'
  ],
  [EVENT_PROFILE_FORM_STEPS.TERMINATE.id]: ['leadExitReasonId']
};

export const checkStepHasErrors = ({ fieldsErrorConfig, errors, stepId }) =>
  Boolean(fieldsErrorConfig[stepId].find((field) => errors[field]));

const fetchUserEventInformationStaticAPIs = async ({
  setStaticUserEventInformation,
  setShowToast
}) => {
  const result = await apiResponseParser({
    listOfAPI: [
      getCheckoutEventsAPI(),
      getCityDetailsAPI(),
      getContactMethodsAPI(),
      getEventSiteTypeListAPI(),
      getEventVerticalsAPI(),
      getLeadExitReasonsAPI()
    ],
    setShowToast
  });
  if (result) {
    const [
      checkoutEvents,
      cityList,
      contactMethods,
      eventSiteTypeList,
      eventVerticals,
      leadExitReasons
    ] = result;

    const parseCheckoutEvents = checkoutEvents.map(
      ({ event: { name }, id }) => ({ id, name })
    );
    const parseLeadExitReasons = leadExitReasons.filter(
      ({ name }) => name !== 'Reason Unknown'
    );

    setStaticUserEventInformation({
      checkoutEvents: parseCheckoutEvents,
      cityList,
      contactMethods,
      eventSiteTypeList,
      eventVerticals,
      leadExitReasons: parseLeadExitReasons
    });
  }
};

export const fetchUserEventInformationOnPageLoad = async ({
  setLoading,
  setShowToast,
  setStaticUserEventInformation,
  setUserEventInformation,
  userEventId
}) => {
  setLoading(true);
  const { status, entity, message } = await getUserEventCartDetailsAPI({
    userEventId
  });
  const { userEventInformation } = entity || {};
  if (status && userEventInformation) {
    setUserEventInformation(userEventInformation);
  }
  await fetchUserEventInformationStaticAPIs({
    setStaticUserEventInformation,
    setShowToast
  });
  setLoading(false);
  (!status || !userEventInformation) &&
    setShowToast({ message, show: true, successToast: status });
};

export const submitUserEventProfile = async ({
  data,
  eventFormDetails,
  reset,
  selectedEventRequestVerticals,
  setError,
  setEventFormDetails,
  setFormActiveStepId,
  setUserEventDetailEditMode,
  setUserEventInformation,
  setValue,
  userInfo
}) => {
  const selectedEventType = eventFormDetails.checkoutEvents.find(
    (type) => type.id === data.checkoutEventId
  );

  const { status, entity, message } = await updateUserEventAPI({
    userId: userInfo.id,
    userEventId: data.userEventId,
    userEventDetail: {
      ...data,
      contactMethodId: eventFormDetails.selectedEventContactMethod,
      eventAddress: eventFormDetails.userAddress,
      eventId: selectedEventType?.id || '',
      eventName: selectedEventType?.name || '',
      eventSiteTypeId: eventFormDetails.selectedEventSiteType,
      eventStartTime: eventFormDetails.selectedEventStartTime,
      eventTypeName: selectedEventType?.name || '',
      eventVerticalIds: selectedEventRequestVerticals,
      source: USER_EVENT_SOURCE.PLANNER_PORTAL_UPDATE_USER_EVENT,
      userId: userInfo.id,
      zendeskTicketId: eventFormDetails.zendeskTicketId
    }
  });

  const zendeskTicketDetailDefault = {
    assigneeName: null,
    assigneeEmail: null,
    createdAt: null,
    inquiryGenerationStatus: null,
    inquiryNumber: null,
    inquiryToQuoteStatus: null,
    priority: null,
    quoteNumber: null,
    quoteToClosureStatus: null,
    requesterType: null,
    submissionDate: null,
    totalGEVQuoted: null
  };

  if (status && entity) {
    setUserEventInformation(entity);
    const { userEventDetails: [{ eventTitle = '' } = {}] = [] } = entity;
    setEventFormDetails((prev) => ({
      ...prev,
      userEventId: entity?.id
    }));
    reset({
      ...data,
      eventTitle
    });
    const zendeskTicketDetail =
      entity?.zendeskTicketDetail || zendeskTicketDetailDefault;
    Object.keys(zendeskTicketDetail).forEach((zendeskDetailKey) =>
      setValue(zendeskDetailKey, zendeskTicketDetail[zendeskDetailKey])
    );
    setValue('leadExitReasonId', entity.leadExitReasonId);
    setEventFormDetails((prev) => ({
      ...prev,
      toastStatus: true,
      toastMessage: 'Event Details Added Successfully.'
    }));
    setUserEventDetailEditMode(false);
    return;
  }
  if (message) {
    setError('leadExitReasonId', { type: 'custom', message });
    setFormActiveStepId(EVENT_PROFILE_FORM_STEPS.TERMINATE.id);
    return;
  }
  setEventFormDetails((prev) => ({
    ...prev,
    toastStatus: false,
    toastMessage:
      'Oops, something went wrong. Failed to create a user event. ' +
      'Please use the ops portal to check if this Zendesk ticket is not ' +
      'already attached to another event profile.'
  }));
};

export const userAddressObject = {
  buildingName: '',
  cityId: '',
  formattedAddress: '',
  houseNumber: '',
  landmark: '',
  latitude: 0,
  locationSelected: false,
  longitude: 0
};
